import { Application, ApplicationType, Entity, Site } from '../..';
import { observable, action, computed } from 'mobx';
import moment, { type Moment } from 'moment';
import { isEmpty } from '../../../utils';
import TgGroupsApplicationSettings from '../Settings/TelegramGroupsApplicationSettings';
import MetaConversionsApplicationSettings from '../Settings/MetaConversionsApplicationSettings';
import YandexMetrikaApplicationSettings from '../Settings/YandexMetrikaApplicationSettings';

/**
 * Represents the associated application to the school
 */
export default class SchoolApplication extends Entity {
    constructor(app?: Partial<SchoolApplication>) {
        super(app);
        if (app) this.update(app);
    }

    @observable title: string;
    @observable type: ApplicationType;
    @observable settingsJson: string;
    @observable createdDate: Moment;
    @observable updatedDate: Moment | null;
    @observable webhookInvalidated: boolean;

    @observable application: Application;
    @observable sites: Site[];

    @computed get settings() {
        if (isEmpty(this.settingsJson))
            return null;
        return JSON.parse(this.settingsJson);
    }

    @computed get tgGroupsSettings() {
        return TgGroupsApplicationSettings.fromJson(this.settings ?? {});
    }

    @computed get metaConversionsSettings() {
        return MetaConversionsApplicationSettings.fromJson(this.settings ?? {});
    }

    @computed get yandexMetrikaSettings() {
        return YandexMetrikaApplicationSettings.fromJson(this.settings ?? {});
    }

    @action update(schoolApp: Partial<SchoolApplication>, allowUndefined = false) {
        super.update(schoolApp, allowUndefined);
    }

    clone(entity?: Partial<SchoolApplication>): SchoolApplication {
        return new SchoolApplication({
            id: this.id,
            title: this.title,
            type: this.type,
            settingsJson: this.settingsJson,
            softDeleted: this.softDeleted,
            softDeletedDate: this.softDeletedDate,
            createdDate: this.createdDate,
            updatedDate: this.updatedDate,
            application: this.application,
            webhookInvalidated: this.webhookInvalidated,
            sites: this.sites.map(x => x.clone()),
            ...entity
        });
    }

    hasChanges(app: SchoolApplication): boolean {
        return this.title !== app.title ||
            this.type !== app.type ||
            this.settingsJson !== app.settingsJson ||
            !this.sites.isEquals(app.sites, (a, b, aIndex, bIndex) => aIndex == bIndex && !a.hasChanges(b));
    }

    static fromJson(json: any): SchoolApplication {
        return new SchoolApplication({
            id: json.id,
            title: json.title,
            type: json.type,
            settingsJson: json.settingsJson,
            softDeleted: json.softDeleted,
            softDeletedDate: json.softDeletedDate ? moment(json.softDeletedDate) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : null,
            webhookInvalidated: json.webhookInvalidated,
            application: json.application ? Application.fromJson(json.application) : undefined,
            sites: json.integrations ? json.integrations.map((x: any) => Site.fromJson(x.site)) : [],
        });
    }
}